import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" overflow-y-hidden">
      <footer className=" bg-primary-450 flex justify-center items-center">
        <div className=" md:w-10/12  w-full  md:px-0 px-4 space-y-10    py-10  lg:space-y-7 ">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <div className=" flex  items-center gap-2 ">
                <Link to="/">
                  <img
                    src={logo}
                    className=" h-20 object-cover cursor-pointer"
                    alt=" logo"
                  />
                </Link>
                <p className="text-accent-400 text-xl font-semibold">
                  LALITPUR NEO CLINIC
                </p>
              </div>
              <p className="mt-4 max-w-xs hover:opacity-75 text-gray-300">
                Lalitpur Neo Clinic is located in Gwarkho-6, Lalitpur, Kathmandu
                Nepal
              </p>

              <ul className="mt-8 flex gap-7">
                <li>
                  <Link
                    to="https://www.facebook.com/profile.php?id=61559808046965&mibextid=ZbWKwL"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                  >
                    <span className="sr-only">Facebook</span>

                    <svg
                      className="h-8 w-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </li>

                <li>
                  <Link
                    to="https://www.instagram.com/neochildcare?igsh=bzJ1eDFxb29iYWRz"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                  >
                    <span className="sr-only">Instagram</span>

                    <svg
                      className="h-8 w-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>

            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
              className="grid grid-cols-1  gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3"
            >
              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  Our Services
                </p>

                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  <li>
                    <Link
                      to="/occupational"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Occupational Therapy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/autism"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Autism Diagnosis (ADOS)
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/psychology"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Psychology Counseeling
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/sensory"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Sensory Integration
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/behavioural"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Behavioural Therapy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/psychology"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Physiotherapy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/speech"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Speech Therapy
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  Helpful Links
                </p>

                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  <li>
                    <Link
                      to="/about"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      About Us{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Contact Us{" "}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/gallery"
                      className="text-gray-300  text-[17px] md:text-[18px] transition hover:opacity-75"
                    >
                      {" "}
                      Gallery
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-200 md:text-2xl text-xl">
                  24/7 Hours Supports
                </p>

                <ul className="md:mt-6 mt-4 space-y-5 text-sm">
                  <li>
                    <p className="text-gray-300  flex gap-2 text-[17px] md:text-[18px] transition hover:opacity-75">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                      lalitpurneoclinic@gmail.com
                    </p>
                  </li>

                  <li>
                    <p className="text-gray-300  flex gap-2 text-[17px] md:text-[18px] transition hover:opacity-75">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>{" "}
                      + 977 9702451907
                    </p>
                  </li>

                  <li>
                    <p className="text-gray-300  flex gap-2 text-[17px] md:text-[18px] transition hover:opacity-75">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                      Gwarkho-6 Lalitpur
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.cloudsnepalweb.com/"
            className="hover:opacity-75 cursor-pointer flex justify-center items-center text-md text-gray-300"
          >
            &copy; 2024. Lalitpur Neo Clinic, Develop & Design by Clouds Nepal
            Web Pvt.Ltd
          </a>
        </div>
      </footer>
    </div>
  );
}
