import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages.jsx/About";
import AllServices from "./pages.jsx/AllServices";
import Home from "./pages.jsx/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Physiotherapy from "./pages.jsx/Physiotherapy";
import Psychology from "./pages.jsx/Psychology ";
import Occupational from "./pages.jsx/Occupational ";
import Autism from "./pages.jsx/Autism";
import Speech from "./pages.jsx/Speech";
import Behavioural from "./pages.jsx/Behavioural ";
import Sensory from "./pages.jsx/Sensory";
import Contact from "./pages.jsx/Contact";
import Message from "./components/Message";
import Executive from "./pages.jsx/Executive";
import Asmi from "./pages.jsx/Asmi";
import Book from "./components/Book";
import BookAppointment from "./pages.jsx/BookAppointment";
import { Toaster } from "react-hot-toast";
import Gallery from "./pages.jsx/Gallery";
import Teams from "./pages.jsx/Teams";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Shamed from "./pages.jsx/Shamed";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="  overflow-x-hidden ">
      <BrowserRouter>
        <Navbar />
        <div className="  md:py-32 py-24">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/executive" element={<Executive />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/asmi" element={<Asmi />} />
            <Route path="/shamed" element={<Shamed />} />

            <Route path="/bookAppointment" element={<BookAppointment />} />
            <Route path="/allServices" element={<AllServices />} />
            <Route path="/physiotherapy" element={<Physiotherapy />} />
            <Route path="/psychology" element={<Psychology />} />
            <Route path="/occupational" element={<Occupational />} />
            <Route path="/autism" element={<Autism />} />
            <Route path="/speech" element={<Speech />} />
            <Route path="/behavioural" element={<Behavioural />} />
            <Route path="/sensory" element={<Sensory />} />
          </Routes>{" "}
        </div>
        <Book />
        <Toaster />
        <Message />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
