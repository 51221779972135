import React from "react";
import { Link } from "react-router-dom";

export default function Book() {
  return (
    <div className="  fixed -right-16 md:bottom-56   bottom-44 z-50 flex   justify-start items-center">
      <Link
        to="/bookAppointment"
        className=" flex  bg-accent-450 text-white px-5 py-2 -rotate-90  rounded-md  hover:bg-accent-400 duration-700"
      >
        Book An Appointment
      </Link>
    </div>
  );
}
