import React from "react";

// import { FiCreditCard, FiMail, FiUser, FiUsers } from "react-icons/fi";
import icon1 from "../assets/pi.jpg";
import icon2 from "../assets/psyi.jpg";
import icon3 from "../assets/oi.jpg";
import icon4 from "../assets/aui.jpg";
import icon5 from "../assets/babyi.png";
import icon6 from "../assets/bi.jpg";
import icon7 from "../assets/si.jpg";
import { Link } from "react-router-dom";

export default function OurServices() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className="p-4 md:-mt-12 -mt-8 flex justify-center  md:px-32 flex-col"
    >
      <p className="text-accent-450 text-2xl md:text-4xl font-semibold md:mb-14 mb-8 flex md:justify-center items-center">
        OUR NEO-CLINIC SERVICES
      </p>
      <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
        <Card title="Physiotherapy" href="/physiotherapy" icon={icon1} />
        <Card title="Psychology Counseeling" href="/psychology" icon={icon2} />
        <Card title="Occupational Therapy" href="/occupational" icon={icon3} />
        <Card title="Autism Diagnosis (ADOS)" href="/autism" icon={icon4} />
        <Card title="Speech Therapy" href="/speech" icon={icon5} />
        <Card title="Behavioural Therapy" href="/behavioural" icon={icon6} />
        <Card title="Sensory Integration" href="/sensory" icon={icon7} />
      </div>
    </div>
  );
}

const Card = ({ title, icon, href }) => {
  return (
    <Link
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      to={href}
      className="flex flex-col justify-center items-center w-full p-5 rounded border-[1px] border-slate-300 relative overflow-hidden group bg-white"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-primary-400 to-primary-400 translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300" />

      {/* <img
        src={icon}
        alt={title}
        className="absolute z-10 -top-12 -right-12 w-24 h-24 text-slate-100 group-hover:rotate-12 transition-transform duration-300"
      /> */}
      <img
        src={icon}
        alt={title}
        className="mb-2 h-28 w-28 object-cover  rounded-full  text-primary-400 group-hover:text-white transition-colors relative z-10 duration-300"
      />
      <h3 className="font-medium text-lg text-slate-700 group-hover:text-white relative z-10 duration-300">
        {title}
      </h3>
    </Link>
  );
};
