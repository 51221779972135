import React from "react";
import occu from "../assets/baby.jpg";

export default function WhyChoose() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className=" flex justify-center items-center bg-primary-450 md:h-[82vh] h-full"
    >
      <div className=" md:px-0 px-4 md:py-0 py-11 md:flex justify-between md:gap-28  gap-16 items-center md:w-10/12 w-full">
        <div className=" relative  overflow-hidden bg-cover bg-no-repeat md:flex-1">
          <img
            className=" transition duration-700 ease-in-out hover:scale-105 hidden md:flex  md:h-[82vh]  md:rounded-none rounded-md w-full h-full md:pb-0 pb-5 object-cover cursor-pointer"
            src={occu}
            alt=""
          />
        </div>

        <div className=" md:flex-1 flex  flex-col gap-6">
          <div className="  cursor-pointer text-gray-100  text-3xl md:text-4xl font-semibold">
            Why Choose Neo Clinic ?
          </div>
          <div className=" cursor-pointer text-gray-300 md:text-2xl text-xl font-semibold ">
            Choose Neo Clinic for expert therapy tailored to your needs,
            fostering lasting well-being.
          </div>

          <div className="  md:mt-10  z-20  mt-7 grid  md:gap-12 gap-10 justify-center items-center">
            {counts.map((count, index) => (
              <div key={index} className=" flex  gap-10 items-center">
                <div className="   z-10 text-gray-500  md:text-6xl text-5xl  font-semibold">
                  {count.count}
                </div>
                <div className=" flex justify-center flex-col gap-2">
                  <p className="  text-gray-300  cursor-pointer  hover:text-gray-200 duration-300 md:text-3xl text-2xl font-semibold">
                    {count.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
const counts = [
  {
    name: " Family Center Care",
    desc: "Excellent Team of Kathmandu in Nepal",
    count: 1,
  },
  {
    name: " State of Art Facility",
    desc: "Advanced Technology for the right diagnosis, treatment, & quick recovery",
    count: 2,
  },
  {
    name: "Complete Theraphy Support For Parents and Children  ",
    desc: "All-time Care for Neo-Emergencies",
    count: 3,
  },
];
