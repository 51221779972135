import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import spee from "../assets/h3.jpg";

export default function Speech() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={spee}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Speech Therapy service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Speech Therapy and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Pediatric speech-language pathologists (SLPs) help children
              communicate effectively by enhancing their verbal and non-verbal
              skills. They also address feeding and swallowing issues, which
              involve complex mouth muscles. SLPs work with children from
              newborns to teenagers, improving: Speech: Articulation,
              stuttering, and voice disorders; Receptive Language: Understanding
              and processing information; Expressive Language: Producing words
              and forming sentences. Children see SLPs for various reasons,
              including difficulty with feeding, articulation, fluency, and
              understanding or using language.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Each child’s therapy is tailored to their unique needs, with
              sessions designed to be engaging and playful. SLPs provide tools
              for parents and children to practice at home, creating lasting
              improvements. Follow your child’s communication milestones and
              consult an SLP for personalized guidance.
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
