import React from "react";
import smrit from "../assets/smrit.jpg";
import simran from "../assets/simran.jpg";
import shamed from "../assets/shamed.jpg";
import ankit from "../assets/ankit.jpg";
import anjali from "../assets/anjali.jpg";
import lata from "../assets/lata.jpg";
import sushila from "../assets/sushila.jpg";
import sarana from "../assets/sarana.jpg";
import Medha from "../assets/me.jpg";
import { Link } from "react-router-dom";

export default function HomeTeam() {
  return (
    <section className=" md:px-0  px-8 flex justify-center   items-center">
      <div className=" md:w-10/12 w-full">
        <div className="mt-5">
          <ul className="grid md:gap-16 gap-12 sm:grid-cols-2 md:grid-cols-3">
            {team.map((item, idx) => (
              <Link
                to={item.path}
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1500"
                key={idx}
                className=" border px-5 py-5 rounded-md  shadow-md duration-500 hover:shadow-accent-400  shadow-accent-400"
              >
                <div className="w-full h-80 sm:h-52 md:h-96">
                  <img
                    src={item.avatar}
                    className="w-full   hover:translate-y-px  duration-300  cursor-pointer h-full object-cover object-center shadow-md rounded-xl"
                    alt=""
                  />
                </div>
                <div className="mt-4">
                  <h4 className="text-xl text-primary-350 font-serif italic  font-semibold">
                    {item.name}
                  </h4>
                  <p className="  font-serif  font-extralight mt-2 ">
                    {item.title}
                  </p>
                </div>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

const team = [
  {
    avatar: shamed,
    name: "Shamed Katila Shrestha",
    title:
      "Clinical Director (Pediatric Physiotherapist with 12 years of experience )",
    path: "/shamed",
  },

  {
    avatar: sarana,
    name: "Sarna Maharjan ",
    title:
      "Physiotherapist (Experience: 10 years in field of pediatric neurological disorders) ",
  },
  {
    avatar: Medha,
    name: "Medha Koirala ",
    title: "Consultant Speech Language Pathologist( 16 year experience) ",
  },
  {
    avatar: simran,
    name: "Simran Basnyeat",
    title: "Behavior Therapist with 5 years of experience ",
  },
  {
    avatar: ankit,
    name: "Ankit Bhattarai ",
    title: "Audiologist and Speech Language Pathologist",
  },

  {
    avatar: anjali,
    name: "Anjali Sah ",
    title: "Audiologist and Speech Language Pathologist",
  },
  {
    avatar: smrit,
    name: "Smriti K.C",
    title: "Physiotherapist ",
  },
  {
    avatar: lata,
    name: "Lata Bhatta",
    title: "Physiotherapist ",
  },
  {
    avatar: sushila,
    name: "Shushila Karki",
    title: "Receptionist ",
  },
];
