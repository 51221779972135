import React from "react";
import n5 from "../assets/n5.jpg";

export default function About() {
  return (
    <div className="bg-white flex  md:px-0 px-4 justify-center">
      <div className=" flex justify-center w-full md:w-10/12">
        <div className="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div className="lg:flex lg:flex-col ">
            <p className="mb-6 text-gray-600 sm:text-lg md:mb-8">
              At Neo Clinic, our vision is to be a leading provider of holistic
              therapy services, empowering individuals of all ages to achieve
              their fullest potential. We offer a range of services, including
              occupational, speech, and physiotherapy, as well as psychological
              counseling, all tailored to meet the unique needs of our clients.
            </p>
            <p className=" text-gray-600 sm:text-lg">
              Our goals are to ensure professional excellence and accessibility,
              making high-quality therapy available to everyone. We focus on
              personalized care, community engagement, and innovative practices
              to help individuals develop effective coping strategies, improve
              daily functioning, and gain greater independence and confidence.
            </p>
          </div>

          <div>
            <div className="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
              <img
                src={n5}
                alt="img"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="md:col-span-2">
            <h2 className="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              About us Neo Clinic
            </h2>

            <p className="mb-6 text-gray-600 sm:text-lg md:mb-8">
            Lalitpur Neo Clinic is located in Gwarkho-6,
              Lalitpur, Kathmandu, and is dedicated to enhancing the well-being
              and quality of life for individuals of all ages. Our
              multidisciplinary team provides a range of services, including
              occupational therapy, speech therapy, physiotherapy, and
              psychological counseling, all tailored to meet the unique needs of
              each client. We integrate evidence-based practices with innovative
              techniques to achieve the best outcomes. Our mission is to empower
              clients by helping them develop effective coping strategies,
              improve daily functioning, and gain greater independence and
              confidence, all within a supportive and inclusive environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
