import React, { useRef, useState } from "react";

const ContactForm = () => {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const mailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0ASubject: ${subject}%0D%0AMessage: ${message}`;

      window.open(
        `mailto:lalitpurneoclinic@gmail.com?subject=Message from ${name}&body=${mailBody}`
      );

      formRef.current.reset();
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");

      setShowThankYou(true);

      setTimeout(() => {
        setIsSubmitting(false);
        setShowThankYou(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to send message. Please try again later.", error);
      setIsSubmitting(false);
      // Optionally, you could show an error message to the user
    }
  };

  return (
    <div className="md:px-0 px-4">
      <div className="flex md:justify-center md:items-center mb-5 md:text-2xl text-xl text-black/90">
        Book An Appointment
      </div>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="max-w-md mx-auto p-5 border border-gray-300 rounded"
      >
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="user_name"
            className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
            required
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="user_email"
            className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
            required
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="subject">
            Subject:
          </label>
          <input
            type="text"
            id="subject"
            name="user_subject"
            className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
            required
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="message">
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
            rows="4"
            placeholder="Message"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <button
          type="submit"
          className="md:w-full w-max px-28 bg-primary-400 hover:bg-primary-350 duration-500 text-white md:px-3 py-2 rounded"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send"}
        </button>
      </form>
      {showThankYou && (
        <div className="mt-2 text-green-500" aria-live="polite">
          Thank you! Your message has been sent.
        </div>
      )}
    </div>
  );
};

export default ContactForm;

// import React, { useRef, useState } from "react";
// import emailjs from "@emailjs/browser";

// const ContactForm = () => {
//   const form = useRef();
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     console.log("Form data before sending:");
//     const formData = new FormData(form.current);
//     formData.forEach((value, key) => {
//       console.log(key, value);
//     });

//     emailjs
//       .sendForm(
//         "service_ygr8exy", // Your service ID
//         "template_25i1uqc", // Your template ID
//         form.current,
//         "iEDEHfLa3YHP1l2yL" // Your public key
//       )
//       .then(
//         (result) => {
//           console.log("SUCCESS!", result.status, result.text);
//           alert("Thank you for contacting us, we will get back to you soon.");
//           form.current.reset();
//           setIsSubmitting(false);
//         },
//         (error) => {
//           console.error("FAILED...", error);
//           console.log("Error details:", error);
//           alert(
//             "Sorry, there was an error sending your message. Please try again later."
//           );
//           setIsSubmitting(false);
//         }
//       );
//   };

//   return (
//     <div className="md:px-0 px-4">
//       <div className="flex md:justify-center md:items-center mb-5 md:text-2xl text-xl text-black/90">
//         Book An Appointment
//       </div>
//       <form
//         ref={form}
//         onSubmit={sendEmail}
//         className="max-w-md mx-auto p-5 border border-gray-300 rounded"
//       >
//         <div className="mb-4">
//           <label className="block text-gray-700">Full Name:</label>
//           <input
//             type="text"
//             name="user_name"
//             className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
//             required
//             placeholder="user name"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Subject:</label>
//           <input
//             type="text"
//             name="user_subject"
//             className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
//             required
//             placeholder="subject"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Email:</label>
//           <input
//             type="email"
//             name="user_email"
//             className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
//             required
//             placeholder="email"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Message:</label>
//           <textarea
//             name="message"
//             className="w-full px-3 py-2 border border-gray-300 rounded outline-primary-400"
//             rows="4"
//             placeholder="message"
//             required
//           ></textarea>
//         </div>
//         <button
//           type="submit"
//           className="md:w-full w-max px-28 bg-primary-400 hover:bg-primary-350 duration-500 text-white md:px-3 py-2 rounded"
//           disabled={isSubmitting}
//         >
//           {isSubmitting ? "Sending..." : "Send"}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default ContactForm;
