import React from "react";
import bee from "../assets/h7.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function Behavioural() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={bee}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Behavioural Therapy service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Behavioural Therapy and How Can It Help?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              The Autism Diagnostic Observation Schedule (ADOS): The Autism
              Diagnostic Observation Schedule is an activity-based assessment
              administered by trained clinicians to evaluate communication
              skills, social interaction, and imaginative use of materials in
              individuals who are suspected to have autism spectrum disorder
              (ASD). The ADOS was developed to provide an opportunity to observe
              symptoms and behaviors associated with ASD in a consistent manner
              across different clients, clinicians, and locations. The ADOS is
              currently considered the “gold standard” in the assessment of ASD.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              The assessment takes between 30-60 minutes for a clinician to
              administer. Clients are typically scheduled with a clinician with
              whom they have little to no familiarity to obtain a sample of the
              client’s behavior that is not influenced by previous interactions.
              When toddlers or children are being assessed, the parent may be
              asked to be present in the room for the entire administration. A
              parent’s primary role is to simply observe, although the clinician
              may ask the parent to interact with the child in specific ways at
              certain points during the ADOS. For adolescents and adults, the
              ADOS is typically administered with only the client and clinician
              in the room.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              The results of the assessment are reviewed with families shortly
              following the completion of the comprehensive evaluation. In some
              cases, this is during the same appointment as the assessment. In
              other cases, a separate follow-up appointment may be scheduled to
              go over the results and discuss recommendations. In addition to
              spending time with a clinician to review the results of the
              evaluation and address any questions, families are also provided a
              written report with detailed recommendations. This is typically
              available 1-2 weeks following the assessment.
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
