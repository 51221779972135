import React from "react";
import test from "../assets/h1.jpg";
import explogo from "../assets/explogo.png";
import happy from "../assets/happy.png";

export default function Experience() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className=" overflow-y-hidden  md:px-0 px-4  flex justify-center items-center "
    >
      <div className=" md:flex justify-between  md:gap-56 md:w-10/12">
        <div className=" flex  flex-col md:mt-10 mt-10  md:w-4/12">
          <div className=" flex cursor-pointer hover:-rotate-3 duration-500 justify-center gap-2 z-40 absolute shadow-xl -mt-10  rounded-md  bg-gray-200 md:px-10 px-5 py-4">
            <img className=" object-cover " src={explogo} alt="" />
            <p className=" text-accent-450 text-2xl font-semibold">
               Experience
            </p>
          </div>
          <div>
            <img
              className=" w-full  z-20 relative rounded-full h-96 object-cover cursor-pointer"
              src={test}
              alt=""
            />{" "}
          </div>
          <div className=" flex  cursor-pointer hover:rotate-3 duration-500  justify-end gap-2 z-40  w-max shadow-xl -mt-8  md:ml-40  ml-4 rounded-md  bg-gray-200 md:px-7 px-4 py-4">
            <img className=" object-cover " src={happy} alt="" />
            <p className=" text-accent-450 text-2xl font-semibold">
            Happy Patients
            </p>
          </div>
        </div>
        <div className=" flex md:mt-0 mt-14 flex-col md:gap-8 gap-5 md:w-6/10">
          <h1 className="text-accent-400 text-2xl md:text-4xl font-semibold">
            Experience Advanced therapy Care at Neo Clinic{" "}
          </h1>
          <p className="text-gray-700 md:text-[19px]   md:max-w-7xl text-[18px] ">
            Experience top-tier therapeutic care at Neo Clinic. Our Nepal-based
            facility is equipped with advanced technology and staffed by skilled
            professionals committed to your well-being. Whether you need
            physiotherapy, speech therapy, or specialized treatments, we provide
            personalized care that promotes healing and enhances your quality of
            life. Trust Neo Clinic for exceptional therapy tailored to your
            individual needs.
          </p>
          <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
            Experience advanced therapy care at Neo Clinic in Nepal. Our
            dedicated team and cutting-edge technology ensure personalized
            treatment tailored to your needs, whether it's physiotherapy, speech
            therapy, or specialized treatments. Trust Neo Clinic for exceptional
            care that promotes your well-being.
          </p>
        </div>
      </div>
    </div>
  );
}
