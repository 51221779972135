import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import p from "../assets/h5.jpg";

export default function Psychology() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={p}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
            Psychology Counseeling service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Psychology Counseeling and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Psychology is the scientific study of the mind and behavior. It
              encompasses a wide range of topics, including cognition, emotion,
              perception, motivation, brain functioning, personality, and social
              interactions. Psychologists aim to understand how people think,
              feel, and behave in various situations and to apply this knowledge
              to help individuals improve their lives.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
            Psychology can help in numerous ways. It offers therapeutic interventions such as cognitive-behavioral therapy (CBT), psychotherapy, and counseling to treat mental health conditions like depression, anxiety, and stress. Psychologists also provide support for individuals dealing with life challenges, such as grief, relationship issues, and trauma. Additionally, psychology can enhance personal development, improve relationships, and promote overall well-being by helping people understand and change unhelpful behavior patterns and develop healthier coping strategies. Through assessment, diagnosis, and tailored treatment plans, psychology plays a crucial role in improving mental health and quality of life.
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
