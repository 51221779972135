import React, { useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import asmi from "../assets/asmi.jpg";
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";
import { Link } from "react-router-dom";

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const AuroraHero = () => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, [color]);

  const backgroundImage = "none"; // Set background image to none to remove the top background color
  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

  return (
    <div className="flex flex-col gap-2">
      <motion.section
        style={{
          backgroundImage,
        }}
        className="relative md:-mt-32 -mt-32 grid md:min-h-screen md:place-content-center overflow-hidden bg-primary-450 px-4 md:py-24 py-28 text-gray-200"
      >
        <div className="relative z-10 flex flex-col items-center">
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="max-w-5xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-5xl sm:leading-tight md:text-7xl md:leading-tight md:mt-0 mt-5"
          >
            WELCOME TO THE LALITPUR NEO CLINIC
          </h1>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="my-6 max-w-4xl md:text-center text-base leading-relaxed md:text-lg md:leading-relaxed"
          >
            Our expert team specializes in providing a wide range of therapeutic
            services tailored to your unique needs. We are committed to helping
            you achieve optimal health and well-being in a compassionate and
            supportive environment. Thank you for choosing us for your therapy
            needs!
          </p>
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="md:flex md:gap-20"
          >
            <Link to="/about">
              <motion.button
                style={{
                  border,
                  boxShadow,
                }}
                whileHover={{
                  scale: 1.015,
                }}
                whileTap={{
                  scale: 0.985,
                }}
                className="group relative flex w-fit items-center gap-1.5 rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50 mt-6"
              >
                More About Us
                <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
              </motion.button>
            </Link>
            <Link to="/contact">
              <motion.button
                style={{
                  border,
                  boxShadow,
                }}
                whileHover={{
                  scale: 1.015,
                }}
                whileTap={{
                  scale: 0.985,
                }}
                className="group relative flex w-fit items-center gap-1.5 rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50 mt-6"
              >
                Contact Us
                <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
              </motion.button>
            </Link>
          </div>
        </div>
      </motion.section>
      <Link
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
        to="/asmi"
        className="md:-mt-36 -mt-20 z-40 flex justify-center items-center"
      >
        <div className="w-full hover:shadow-lg duration-500 hover:shadow-accent-400 shadow-accent-400 max-w-xs overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <img
            className="h-72 w-full object-cover"
            src={asmi}
            alt="avatar"
          />

          <div className="py-4 text-center">
            <p className="text-[18px] font-semibold">CHILD NEUROLOGIST</p>
            <p
              className="block text-[18px] mt-1 text-gray-800 dark:text-white"
              tabIndex="0"
              role="link"
            >
              Dr. Asim Shrestha
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AuroraHero;















// import React, { useEffect } from "react";
// import { FiArrowRight } from "react-icons/fi";
// import asmi from "../assets/asmi.jpg";
// import {
//   useMotionTemplate,
//   useMotionValue,
//   motion,
//   animate,
// } from "framer-motion";
// import { Link } from "react-router-dom";

// const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

// const AuroraHero = () => {
//   const color = useMotionValue(COLORS_TOP[0]);

//   useEffect(() => {
//     animate(color, COLORS_TOP, {
//       ease: "easeInOut",
//       duration: 10,
//       repeat: Infinity,
//       repeatType: "mirror",
//     });
//   }, [color]);

//   const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
//   const border = useMotionTemplate`1px solid ${color}`;
//   const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

//   return (
//     <div className="flex flex-col gap-2">
//       <motion.section
//         style={{
//           backgroundImage,
//         }}
//         className="relative md:-mt-32 -mt-32 grid md:min-h-screen md:place-content-center overflow-hidden bg-primary-450 px-4 md:py-24 py-28 text-gray-200"
//       >
//         <div className="relative z-10 flex flex-col items-center">
//           <h1
//             data-aos="fade-down"
//             data-aos-easing="linear"
//             data-aos-duration="1500"
//             className="max-w-5xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-5xl sm:leading-tight md:text-7xl md:leading-tight md:mt-0 mt-5"
//           >
//             WELCOME TO THE LALITPUR NEO CLINIC
//           </h1>
//           <p
//             data-aos="fade-up"
//             data-aos-easing="linear"
//             data-aos-duration="1500"
//             className="my-6 max-w-4xl md:text-center text-base leading-relaxed md:text-lg md:leading-relaxed"
//           >
//             Our expert team specializes in providing a wide range of therapeutic
//             services tailored to your unique needs. We are committed to helping
//             you achieve optimal health and well-being in a compassionate and
//             supportive environment. Thank you for choosing us for your therapy
//             needs!
//           </p>
//           <div
//             data-aos="fade-up"
//             data-aos-easing="linear"
//             data-aos-duration="1500"
//             className="md:flex md:gap-20"
//           >
//             <Link to="/about">
//               <motion.button
//                 style={{
//                   border,
//                   boxShadow,
//                 }}
//                 whileHover={{
//                   scale: 1.015,
//                 }}
//                 whileTap={{
//                   scale: 0.985,
//                 }}
//                 className="group relative flex w-fit items-center gap-1.5 rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50 mt-6"
//               >
//                 More About Us
//                 <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
//               </motion.button>
//             </Link>
//             <Link to="/contact">
//               <motion.button
//                 style={{
//                   border,
//                   boxShadow,
//                 }}
//                 whileHover={{
//                   scale: 1.015,
//                 }}
//                 whileTap={{
//                   scale: 0.985,
//                 }}
//                 className="group relative flex w-fit items-center gap-1.5 rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50 mt-6"
//               >
//                 Contact Us
//                 <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
//               </motion.button>
//             </Link>
//           </div>
//         </div>
//       </motion.section>
//       <Link
//         data-aos="fade-up"
//         data-aos-easing="linear"
//         data-aos-duration="1500"
//         to="/asmi"
//         className="md:-mt-36 -mt-20 z-40 flex justify-center items-center"
//       >
//         <div className="w-full hover:shadow-lg duration-500 hover:shadow-accent-400 shadow-accent-400 max-w-xs overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
//           <img className="h-72 w-full object-cover" src={asmi} alt="avatar" />

//           <div className="py-4 text-center">
//             <p className="text-[18px] font-semibold">CHILD NEUROLOGIST</p>
//             <p
//               className="block text-[18px] mt-1 text-gray-800 dark:text-white"
//               tabIndex="0"
//               role="link"
//             >
//               Dr. Asim Shrestha
//             </p>
//           </div>
//         </div>
//       </Link>
//     </div>
//   );
// };

// export default AuroraHero;
