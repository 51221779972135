import React from "react";
import occu from "../assets/h4.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function Occupational() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={occu}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Occupational Therapy service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Occupational Therapy and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Occupational therapy (OT) is a healthcare profession that helps
              individuals build or improve skills for everyday activities such
              as self-care, productivity, work, and leisure activities such as
              hobbies and socializing, utilizing various techniques, exercises,
              and interventions to enhance independence and functional
              abilities, including adaptive strategies, assistive devices, and
              customized treatment plans. Pediatric occupational therapy aims to
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              increase children's participation and engagement in daily
              activities. There are many different kinds of intervention
              strategies to help families and therapists make informed decisions
              about the best possible care for children with impairments.
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
