import React from "react";
import explog from "../assets/h8.jpg";
import bee from "../assets/h7.jpg";
import occu from "../assets/h4.jpg";
import p from "../assets/h5.jpg";
import physi from "../assets/h2.jpg";
import spee from "../assets/h3.jpg";
import ass from "../assets/h6.jpg";
import { Link } from "react-router-dom";

export default function AllServices() {
  return (
    <div className="  md:px-0 px-4 flex flex-col md:gap-10 gap-6 justify-center items-center ">
      <h1
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="    text-accent-400 md:text-2xl text-xl font-semibold underline "
      >
        All Our Neo-Clinic Services
      </h1>
      <span
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className=" md:px-36 md:text-[19px] text-[16px] text-gray-500"
      >
        Neo Clinic offers comprehensive services including occupational therapy
        for sensory and motor skills, autism diagnosis using ADOS, psychological
        assessments and therapies, individual and group counseling, and
        physiotherapy for rehabilitation and pain management. Their
        multidisciplinary team ensures holistic care, addressing the physical,
        emotional, and psychological needs of clients.
      </span>
      <div className=" flex justify-center items-center md:w-10/12">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2  md:gap-14 gap-10">
          {services.map((service, index) => (
            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
              key={index}
              className=" border  rounded-md group bg-primary-500 hover:bg-primary-450 duration-500"
            >
              <img
                className=" h-52  w-full cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3 px-5 py-6">
                <p className="  border-b py-1 border-gray-400 text-gray-100 md:text-2xl text-xl group-hover:text-gray-200 duration-200">
                  {service.name}
                </p>
                <span className=" text-gray-300 md:text-[19px] text-[16px]  group-hover:text-gray-300 ">
                  {service.desc}
                </span>
                <div className=" flex justify-between">
                  <span></span>
                  <Link
                    to={service.path}
                    className=" w-max bg-gray-200 px-4 py-1 rounded-md  hover:bg-gray-300 duration-500"
                  >
                    {service.btn}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: physi,
    name: "Physiotherapy ",
    desc: " Physiotherapy, also known as physical therapy ......",
    btn: "View Details",
    path: "/physiotherapy",
  },
  {
    img: spee,
    name: "Speech Therapy ",
    desc: " Speech therapy is a specialized field focused on ......",
    btn: "View Details",
    path: "/speech",
  },

  {
    img: occu,
    name: "Occupational Therapy",
    desc: "Occupational therapy (OT) is a health care profession focused ......",
    btn: "View Details",
    path: "/occupational",
  },
  {
    img: p,
    name: "Psychology Counseeling",
    desc: "  Psychology is the scientific study of the mind ......",
    btn: "View Details",
    path: "/psychology",
  },
  {
    img: ass,
    name: "Autism Diagnosis (ADOS)",
    desc: "The Autism Diagnostic Observation Schedule (ADOS) is.....",
    btn: "View Details",
    path: "/autism",
  },

  {
    img: bee,
    name: "Behavioural Therapy",
    desc: " Behavioral therapy is a type of psychotherapy that......",
    btn: "View Details",
    path: "/behavioural",
  },
  {
    img: explog,
    name: "Sensory Integration",
    desc: "Sensory integration is vital for processing sensory ......",
    btn: "View Details",
    path: "/sensory",
  },
];
