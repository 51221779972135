import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import shamed from "../assets/shamed.jpg";
import { Link } from "react-router-dom";

export default function Shamed() {
  return (
    <section className="flex  flex-col  items-center  gap-10 justify-center md:px-0 px-4 ">
      <div className=" flex flex-col gap-2 justify-center items-center ">
        <img
          className=" border px-4 py-5 cursor-pointer w-80 h-96 rounded-2xl object-cover"
          src={shamed}
          alt=""
        />
        <p className="  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
          Shamed Katila Shrestha
        </p>
      </div>
      <div className="shadow-md rounded-md px-3 md:pb-10 pb-7 flex justify-center items-center w-full md:w-10/12">
        <div className="gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className=" px-4 md:space-y-6 space-y-4 mt-6  ">
            <div className=" text-xl flex md:justify-start justify-center items-center  px-4 bg-primary-450 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
              Shamed Katila Shrestha: Pediatric Physiotherapist
            </div>
            <p className="text-gray-700 font-normal text-xl ">
              With over a decade of dedicated experience in pediatric
              physiotherapy, I specialize in working with children who have
              developmental disabilities. My expertise encompasses a range of
              conditions, including cerebral palsy, Down syndrome, autism, and
              other disabilities as well. My core interest lies in pediatric
              neurology, where I focus on improving the quality of life for
              children through specialized therapeutic interventions.
            </p>
            <p className="text-gray-700 md:text-[18px] md:max-w-7xl text-[17px]">
              <strong>Professional Roles and Leadership:</strong>
              <ul className="list-disc list-inside">
                <li>
                  <em>Founder and Executive Director:</em> Neo Child Care Center
                </li>
                <li>
                  <em>Rehabilitation Head:</em> Neo Health Clinic, serving all
                  districts of Kathmandu, Bhaktapur, and Lalitpur
                </li>
                <li>
                  <em>Founder and Executive Director:</em> Orthoplast Rehab
                  Center, Lalitpur
                </li>
                <li>
                  <em>Founder and Executive Director:</em> Neuroplast
                  Rehabilitation Center, Dhangadi
                </li>
                <li>
                  General Secretory, Physiotherapy Rehabilitation Center
                  Association (PRAN)
                </li>
              </ul>
            </p>
            <p className="text-gray-600 md:text-[18px] md:max-w-7xl text-[17px]">
              I have had the privilege of collaborating with numerous
              organizations, contributing to the field of pediatric
              rehabilitation. My work involves leading a multidisciplinary team
              of pediatric doctors and therapists, ensuring comprehensive and
              coordinated care for children with special needs. Through these
              roles, I have been at the forefront of developing and implementing
              innovative therapeutic strategies that address both the physical
              and cognitive aspects of pediatric care.
            </p>
            <p className="text-gray-600 md:text-[18px] md:max-w-7xl text-[17px]">
              My commitment to the field extends beyond individual patient care.
              I am actively involved in advocating for inclusive education and
              the integration of health aspects into Individualized Education
              Programs (IEPs) in collaboration with the Ministry of Education.
              My vision is to bridge the gap between neurotypical and
              neurodiverse children, fostering an environment of inclusiveness
              and support for all.
            </p>
            <p className="text-gray-600 md:text-[18px] md:max-w-7xl text-[17px]">
              Together with my team, I strive to provide holistic and effective
              rehabilitation services, helping children achieve their fullest
              potential and enhancing their participation in everyday life.
            </p>
            <Link
              to="/"
              className="flex gap-2 items-center bg-primary-450 w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
