import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import physi from "../assets/h2.jpg";

export default function Physiotherapy() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={physi}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Physiotherapy service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Physiotherapy and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Pediatric physiotherapists help children with disabilities and
              impairments participate and engage in all aspects of life. They
              work in many settings, from hospitals to outpatient clinics,
              schools and more, to help children and their families achieve
              goals which are important to them. Pediatric physiotherapists
              usually work in a team alongside other health professionals. This
              team has the common goals of supporting the child and family and
              enhancing development.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Goals of treatment focus on components of the International
              Classification of Functioning, Disability and Health (ICF). In
              particular that of participation and activity performance. This is
              done through addressing pain management, improving strength and
              endurance, as well as providing the learning opportunities needed
              for skill acquisition
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
