import React from "react";

import Testmonirals from "../components/Testmonirals";
import WhyChoose from "../components/WhyChoose";

import Faq from "../components/Faq";
import OurServices from "../components/OurServices";
import Features from "../components/Features";
import Experience from "../components/Experience";
import Rajan from "../components/Rajan";
import Review from "../components/Review";
import Banner from "../components/Banner";
import HomeTeam from "../components/HomeTeam";

export default function Home() {
  return (
    <div className=" flex flex-col md:gap-28 gap-20">
      <Rajan />
      <HomeTeam />
      <OurServices />
      <Banner />
      <Testmonirals />
      <Experience />
      <Review />
      <WhyChoose />
      <Features />
      <Faq />
    </div>
  );
}
