import React from "react";
import test from "../assets/lo2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function Testmonirals() {
  return (
    <div className="   flex  flex-col md:gap-7 gap-5 md:px-0 px-4 justify-center items-center">
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="text-accent-450  text-2xl md:text-4xl font-semibold "
      >
        Thoughts From Our Patients
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className=" z-10 md:flex justify-between md:pl-10  md:px-0 px-4   rounded-lg bg-gray-100 w-full  md:w-10/12"
      >
        <div className=" flex justify-center items-center md:w-5/12   z-40 ">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {items.map((item, index) => (
              <SwiperSlide>
                <div
                  key={index}
                  className="  md:pb-10 bg-white  mt-8 rounded-lg px-4 md:px-8 py-6  mb-2 justify-center md:items-center  flex flex-col md:gap-5 gap-4"
                >
                  <p className="text-accent-400 text-xl md:text-3xl font-semibold  flex md:justify-center  items-center">
                    {item.name}
                  </p>
                  <p className="text-gray-700 md:text-[18px]    text-[17px] ">
                    {item.Desc}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" grid md:grid-cols-2 md:py-10  md:mb-0 pb-5  gap-5 text-center overflow-hidden bg-cover bg-no-repeat z-30 md:mt-0 mt-8 md:w-5/12">
          <img className=" object-cover w-full h-full" src={test} alt="" />
        </div>
      </div>
    </div>
  );
}

const items = [
  {
    name: "Dinesh Bhandari",
    Desc: "I've been coming to Neo Clinic for therapy for over a year, and the transformation in my life has been remarkable. The therapists are highly skilled, compassionate, and create a safe, supportive environment. Each session empowers me and enhances my mental health. The personalized treatment plans have made a significant difference in my progress",
  },
  {
    name: "Prakash Sunar",
    Desc: "Neo Clinic has truly been a lifesaver for me. Their comprehensive therapy approach, blending traditional and innovative methods, has helped me overcome unexpected challenges. The staff's dedication ensures I always feel heard and valued. My progress since starting therapy here is beyond what I imagined possible",
  },
  {
    name: "Muna Sapkota",
    Desc: "Neo Clinic excels in providing top-tier therapy services, combining expertise and compassion to support mental health. With skilled therapists and personalized treatment plans. Our nurturing environment and diverse therapies ensure patients experience meaningful and lasting improvements in their well-being.",
  },
  {
    name: "Jun Parsad Rokaya",
    Desc: "At Neo Clinic, we provide 24-hour support for therapy-related emergencies. Our experienced professionals are available around the clock for immediate assistance in critical situations. Whether it's a mental health crisis or urgent need, we're committed to prompt, compassionate care.",
  },
];
