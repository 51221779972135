import React from "react";

export default function Faq() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className=" flex  overflow-y-hidden  md:px-0 px-4 justify-center items-center"
    >
      <div className=" md:flex md:w-10/12 md:shadow-lg rounded-md md:pb-10  justify-between w-full">
        <div className=" md:px-20  md:pt-0 pt-12  w-full">
          <div className="space-y-4">
            <p className=" flex md:justify-center items-center text-accent-450 text-2xl md:text-4xl font-semibold mb-10 ">
              Frequently Asked Questions
            </p>
            <details className="group  rounded-t-2xl  bg-primary-450 hover:bg-primary-400 duration-300 p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  Why is Neo Clinic Therapy considered the best therapy clinic
                  in Nepal ?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                Neo Clinic Therapy is renowned in Nepal for its personalized
                treatment plans, state-of-the-art technology, and highly
                qualified therapists, ensuring top-notch care and effective
                recovery for patients.
              </p>
            </details>
            <details className="group rounded-t-2xl  bg-primary-450 hover:bg-primary-400 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  Why should I choose Neo Therapy over other physical therapy
                  providers ?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                Neo Therapy offers personalized treatment plans tailored to your
                specific needs, using the latest evidence-based practices and
                advanced technology to ensure effective recovery and long-term
                wellness.
              </p>
            </details>
            <details className="group rounded-t-2xl  bg-primary-450 hover:bg-primary-400 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  What makes Neo Therapy's approach unique ?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                Neo Therapy combines traditional physical therapy techniques
                with innovative methods such as rehabilitation, ensuring a
                comprehensive and engaging treatment experience that accelerates
                recovery.
              </p>
            </details>

            <details className="group rounded-t-2xl  bg-primary-450 hover:bg-primary-400 duration-300  p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-100">
                  How experienced are the therapists at Neo Therapy ?
                </h2>

                <span className="shrink-0 rounded-full  bg-gray-200 p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-300">
                Our therapists are highly qualified and experienced, with
                specialized training in various areas of physical therapy. They
                stay updated with continuous education to provide the best care
                possible.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
}
