import React from "react";
import asmi from "../assets/asmi.jpg";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Asmi() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div className=" flex flex-col gap-2 justify-center items-center ">
          <img
            className=" border px-4 py-5 cursor-pointer w-80 h-96 rounded-2xl object-cover"
            src={asmi}
            alt=""
          />
          <p className="  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Dr. Asim Shrestha
          </p>
        </div>
        <div className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10">
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-450 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            Dr. Asim Shrestha
          </div>
          <div className=" text-[18px] text-gray-600">
            Dr. Asim Shrestha’s Pediatric Neurology Clinic in Nepal is a unique
            setup that caters to children with neurological, developmental, and
            behavioral problems. Dr. Asim’s areas of expertise include treating
            intractable epilepsy, autism, cerebral palsy, ADHD, disorders,
            neurometabolic and other neurological problems. The clinic also
            deals in electrophysiology reporting like EEGs, BERA, SSEPs, etc in
            children.
          </div>
          <div className=" text-[18px] text-gray-600">
            Dr. Asim Shrestha is currently working in Bansbari Neuro Hospital
            and International Friendship Children Hospital(IFCH). For
            appointments, call on +977 9702451907, 98603 91547.
          </div>
          <p className=" text-[17px] text-gray-600">
            MBBS, MD, Assistant Professor of Pediatrics(KU)
          </p>
          <p className=" text-[17px] text-gray-600">
            Fellowship in Pediatric Neurology (2 years, Mumbai, INDIA)
          </p>
          <div className=" flex flex-col gap-2">
            <p className="  text-primary-450 md:text-2xl text-xl font-semibold">
              Contact Details Dr. Asim Shrestha
            </p>
            <p>Phone Number :- 9702451907 , 98603 91547</p>
            <p>Email :-shresthaasim53@gmail.com</p>
            <p className="  mb-3">Address :- Satdobato, Lalitpur, Nepal</p>
          </div>
        </div>
        <Link
          to="/executive"
          className="  cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
