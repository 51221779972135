import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import explog from "../assets/hero.jpg";
import bee from "../assets/bi.jpg";
import occu from "../assets/oi.jpg";
import p from "../assets/pi.jpg";
import physi from "../assets/psyi.jpg";
import spee from "../assets/si.jpg";
import ass from "../assets/aui.jpg";
import expo from "../assets/explogo.png";

export default function Navbar() {
  const [state, setState] = useState(false);
  const [drapdownState, setDrapdownState] = useState({
    isActive: false,
    idx: null,
  });

  const location = useLocation();
  console.log(location.pathname);

  // Replace / paths with your paths

  const navigation = [
    { title: "Home", path: "/", isDrapdown: false },
    {
      title: "Our Services ",
      path: "/allServices",
      isDrapdown: true,
      navs: dropdownNavs,
    },
    { title: "About Us", path: "/about", isDrapdown: false },

    { title: "Gallery", path: "/gallery", isDrapdown: false },
    { title: "Our Teams", path: "/teams", isDrapdown: false },
    { title: "Contact Us", path: "/contact", isDrapdown: false },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setState(false); // Close the mobile menu on navigation
  }, [location.pathname]);

  return (
    <div className="z-50  fixed left-0 right-0 top-0">
      <nav
        className={`relative z-40 bg-gray-100 w-full md:static md:text-sm md:border-none ${
          state ? "shadow-lg rounded-b-xl md:shadow-none" : ""
        }`}
      >
        <div className="items-center  gap-x-14 px-4 max-w-screen-xl mx-auto md:flex  md:px-0">
          <div className="flex items-center justify-between py-3  md:py-4 md:block">
            <Link to="/" className=" flex  items-center gap-2  ">
              <div>
                <img
                  src={logo}
                  className=" h-12 w-12 object-cover cursor-pointer"
                  alt=" logo"
                />
              </div>
              <p className="text-accent-450 flex justify-center items-center text-[17px] font-serif font-semibold">
                LALITPUR NEO CLINIC
              </p>
            </Link>

            <div className="md:hidden">
              <button
                className="text-primary-350  cursor-pointer font-semibold"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-8 h-8   text-primary-350  cursor-pointer font-semibold"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-end space-y-6 md:flex md:space-x-6 md:space-y-0">
              {navigation.map((item, idx) => {
                return (
                  <li key={idx}>
                    {item.isDrapdown ? (
                      <button
                        className="w-full flex items-center md:justify-between gap-1 font-serif text-gray-700 md:font-semibold   hover:text-primary-350 md:text-[20px] text-[18px]"
                        onClick={() =>
                          setDrapdownState({
                            idx,
                            isActive: !drapdownState.isActive,
                          })
                        }
                      >
                        {item.title}
                        {drapdownState.idx === idx && drapdownState.isActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button>
                    ) : (
                      <Link
                        className={`${
                          location.pathname === item.path
                            ? " text-primary-350  font-semibold "
                            : ""
                        }     bloc1 font-serif text-gray-700 md:font-semibold   hover:text-primary-350 md:text-[20px] text-[18px]`}
                        to={item.path}
                      >
                        {item.title}
                      </Link>
                    )}
                    {item.isDrapdown &&
                    drapdownState.idx === idx &&
                    drapdownState.isActive ? (
                      <div className=" md:px-0 px-4 bg-primary-450 md:overflow-hidden   overflow-y-scroll md:h-max  h-[60vh] mt-6 inset-x-0 top-20 w-full md:absolute md:border-y md:shadow-md md:mt-0">
                        <ul className="max-w-screen-xl mx-auto grid items-center gap-6  md:grid-cols-2 lg:grid-cols-3 md:py-10 py-6">
                          {item?.navs.map((dropdownItem, idx) => (
                            <li key={idx}>
                              {/* <p className="text-indigo-600 text-sm">
                                {dropdownItem.label}
                              </p> */}
                              <ul className="mt-5 space-y-6  hover:scale-105 duration-500">
                                {dropdownItem.navs.map((navItem, idx) => (
                                  <li key={idx} className="group">
                                    <a
                                      href={navItem.path}
                                      className="flex gap-3 items-center"
                                    >
                                      <img
                                        className="w-12 h-12 object-cover rounded-full border-4  border-gray-200 bg-indigo-50  text-primary-300 flex items-center justify-center   duration-500 group-hover:text-white md:w-14 md:h-14"
                                        src={navItem.icon}
                                        alt=""
                                      />
                                      <div>
                                        <span className="   text-gray-100  duration-200  text-[17px]  md:text-[19px]">
                                          {navItem.title}
                                        </span>
                                        <p className=" text-[15px] text-gray-300 group-hover:translate-x-1 duration-700 group-hover:text-gray-400 mt-1">
                                          {navItem.desc}
                                        </p>
                                      </div>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      {state ? (
        <div
          className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
          onClick={() => setState(false)}
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

const dropdownNavs = [
  {
    label: "Products",
    navs: [
      {
        icon: expo,
        title: "All Our Services",
        desc: "Lalitpur Neo-Clinic All Services...",
        path: "/allServices",
      },
    ],
  },
  {
    label: "Products",
    navs: [
      {
        icon: physi,
        title: "Physiotherapy",
        desc: " Physiotherapy, also known as physical therapy...",
        path: "/physiotherapy",
      },
    ],
  },
  {
    label: "Resources",
    navs: [
      {
        icon: p,
        title: " Psychology Counseeling",
        desc: " Psychology is the scientific study of the mind ......",
        path: "/psychology",
      },
    ],
  },
  {
    label: "Company",
    navs: [
      {
        icon: occu,
        title: "Occupational Therapy",
        desc: "Occupational therapy (OT) is a health care......",
        path: "/occupational",
      },
    ],
  },

  {
    label: "Company",
    navs: [
      {
        icon: ass,
        title: "Autism Diagnosis (ADOS)",
        desc: "The Autism Diagnostic Observation Schedule .....",
        path: "/autism",
      },
    ],
  },
  {
    label: "Company",
    navs: [
      {
        icon: spee,
        title: "Speech Therapy ",
        desc: "Speech therapy is a specialized field focused on .....",
        path: "/speech",
      },
    ],
  },

  {
    label: "Company",
    navs: [
      {
        icon: bee,
        title: "Behavioural Therapy",
        desc: "Behavioral therapy is a type of psychotherapy ....",
        path: "/behavioural",
      },
    ],
  },
  {
    label: "Company",
    navs: [
      {
        icon: explog,
        title: " Sensory Integration",
        desc: "Sensory integration is vital for processing....",
        path: "/sensory",
      },
    ],
  },
];
