import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import explog from "../assets/h8.jpg";

export default function Sensory() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={explog}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Sensory Integration service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Sensory Integration and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Sensory integration is vital for processing sensory information
              and regulating emotions, behavior, and motor skills. Occupational
              therapists use structured activities in sensory integration
              therapy to aid individuals with sensory processing issues, aiming
              to improve their abilities and self-regulation. Activities such as
              swinging or playing with textures help individuals better process
              sensory input and respond appropriately, enhancing their overall
              functioning.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              Sensory integration therapy is beneficial for individuals with
              autism spectrum disorder, sensory processing disorder, ADHD, and
              developmental delays. It targets sensory challenges to enhance
              overall functioning and quality of life. Tailored interventions
              help individuals develop effective coping strategies, engage
              comfortably in daily activities, and gain independence and
              confidence in their environment..
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
