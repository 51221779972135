import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import ass from "../assets/h6.jpg";

export default function Autism() {
  return (
    <section className=" flex justify-center md:px-0 px-4 items-center">
      <div className="   shadow-md  rounded-md px-3  md:pb-10 pb-7  flex justify-center items-center w-full md:w-10/12">
        <div className=" gap-x-20 sm:px-4 md:px-0 lg:flex">
          <div className="flex-1 sm:hidden lg:block">
            <img
              src={ass}
              className=" object-cover h-96 w-96 rounded-lg"
              alt=""
            />
          </div>
          <div className="max-w-xl px-4 md:space-y-6 space-y-4  mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h3 className="text-accent-400 text-2xl md:text-4xl font-semibold ">
              Autism Diagnosis (ADOS) service
            </h3>
            <p className="text-gray-700  font-normal text-2xl sm:text-3xl">
              What is Autism Diagnosis (ADOS) and How Can It Help ?
            </p>
            <p className="text-gray-700 md:text-[18px]   md:max-w-7xl text-[17px] ">
              The Autism Diagnostic Observation Schedule (ADOS) is a
              standardized diagnostic tool used to assess and diagnose autism
              spectrum disorder (ASD). It involves a series of structured and
              semi-structured tasks and observations that allow clinicians to
              evaluate social interaction, communication, play, and repetitive
              behaviors associated with autism.
            </p>
            <p className="text-gray-600 md:text-[18px]   md:max-w-7xl text-[17px] ">
              ADOS can help by providing a reliable and comprehensive assessment
              of an individual's behavior and developmental history. This
              detailed evaluation aids in accurately diagnosing autism, which is
              crucial for planning appropriate interventions and support
              strategies. Early and accurate diagnosis through ADOS enables
              access to tailored therapies and educational programs, helping
              individuals with autism improve their social, communication, and
              adaptive skills, thereby enhancing their overall quality of life.
            </p>
            <a
              href="/allServices"
              className=" flex gap-2 items-center bg-primary-450  w-max px-8 py-1 text-gray-100 rounded-md hover:bg-primary-400 duration-500"
            >
              <LuMoveLeft size={20} />
              <button>Back</button>{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
