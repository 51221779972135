import React from "react";
import p from "../assets/baby4.jpg";

export default function Features() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      className="  overflow-y-hidden  z-0 md:px-0 px-4 flex justify-center items-center"
    >
      <div className="    md:flex justify-between w-full md:w-10/12">
        <div
          className=" 
    flex flex-col  md:gap-10 gap-7 md:w-6/12"
        >
          <h1 className="text-accent-450 text-2xl md:text-4xl font-semibold ">
            Features Of Neo-Clinic !
          </h1>
          <div className=" text-[19px] text-gray-800 grid md:grid-cols-2 gap-5">
            <li> 100% Patient Satisfaction</li>
            <li>Speech Therapy Available</li>
            <li> Patient-Centered Care Models</li>
            <li> Advanced Diagnostic Technology</li>
            <li> Integrated Digital Health Records</li>
            <li> Psychology Counseeling</li>
            <li> Access to Experienced Doctors</li>
            <li> Eco-Friendly Practices</li>
            <li> Respect for Patient Preferences</li>
            <li> 24/7 Patient Care</li>
          </div>
        </div>
        <div className="   overflow-x-hidden   overflow-y-hidden flex  flex-col  md:mt-0 mt-20  md:w-4/12">
         
          <div>
            <img
              className="  hover:-rotate-[15deg] duration-700 w-full  z-40 relative rounded-full h-96 object-cover cursor-pointer"
              src={p}
              alt=""
            />{" "}
          </div>
          <div className=" flex  rounded-full  w-36 h-36  -mt-36   cursor-pointer hover:-rotate-3 duration-500  gap-2 z-20  shadow-xl   bg-primary-250 "></div>
        </div>
      </div>
    </div>
  );
}
